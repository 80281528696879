:root {
    --primary-color: #1877f2;

    --message-background-color: rgb(228, 230, 235);
    --section-separator-background-color: #e5e5e5;
    --web-wash-background-color: rgb(240, 242, 245);
    --comment-background-color: rgb(240, 242, 245);
    --hover-background-color: rgba(141, 141, 141, 0.178);
    --overlay-background-color: rgba(244, 244, 244, 0.8);
    --disabled-button-background-color: rgb(228, 230, 235);
    --secondary-highlight-background-color: #e7f3ff;

    --disabled-button-text-color: rgb(188, 192, 196);

    --primary-button-color: rgb(27, 116, 228);

    --secondary-button-background-color: rgb(228, 230, 235);

    --primary-text-color: rgb(5, 5, 5);
    --secondary-text-color: rgb(101, 103, 107);
    --primary-deemphasized-button-text: rgb(24, 119, 242);

    --form-text-color: rgb(101, 103, 107);

    --background-color: #f0f2f5;
    --secondary-background-color: #ffffff;
    --disabled-background-color: grey;

    --hover-overlay: rgba(0, 0, 0, 0.05);
    --always-dark-overlay: rgba(0, 0, 0, 0.4);

    --standard-border-radius: max(0px, min(8px, -999900% - 39996px + 999900vw)) /
        8px;
    --shadow-1: rgba(0, 0, 0, 0.1);
    --shadow-2: rgba(0, 0, 0, 0.2);
    --navbar-box-shadow: 0 0 3px 1px var(--shadow-1);
    --card-box-shadow: 0 12px 28px 0 var(--shadow-2),
        0 2px 4px 0 var(--shadow-1);
    --search-box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 12px 0px,
        rgba(255, 255, 255, 0.5) 0px 0px 0px 0px inset;

    --default-font-family: Roboto, sans-serif;
}

body {
    margin: 0;
    font-family: var(--default-font-family);
}
